import React from "react";
import "./Projects.scss";
import { data1, data2 } from "./ProjectsData";
import ProjectsContact from "./ProjectContact/ProjectContact";
import { Helmet } from "react-helmet";

const Projects = () => {
  return (
    <div className="projectMain">
      <Helmet>
        <title>Our Projects | E&I Engineers' Success Stories</title>
        <meta
          name="description"
          content="Browse through our successful projects at E&I Engineers. See how we have delivered exceptional electrical engineering solutions, from substation erection to high mast erection and maintenance services."
        />
      </Helmet>
      <section className="projectSection">
        <h1 className="title  slide-bottom">E&I Engineers Projects</h1>
        <div className="directContracts">
          <h2 className="subheading slide-right">Current Projects</h2>
          <div className="projectColumn">
            <div className="picPan opacityF2T slide-left">
              <img
                src="./images/projects/Dip-Auxiliary-Package-Project-at-VedantaLtd.jpg"
                alt=""
                className="project-image"
              />
            </div>
            <div className="textPan slide-left">
              <h2 className="heading">
                Dip Auxiliary Package Project
              </h2>
              <h3>
                <strong>Client:</strong> Vedanta Ltd <br />
                <strong>Subcontractor:</strong> MCK Kutty Engineers Pvt Ltd
                <br />
                <strong>Site:</strong> Navelim, Goa
              </h3>
              <p className="project-description">
                E&I Engineers is proud to be actively engaged in the Dip
                Auxiliary Package Project for Vedanta Ltd, one of India's
                leading global natural resource companies. This project,
                situated in Navelim, Goa, is being executed under the
                subcontract from MCK Kutty Engineers Pvt Ltd. Our team's
                expertise in electrical and instrumentation engineering is being
                fully leveraged to deliver precise, efficient, and high-quality
                solutions tailored to Vedanta's operational needs.
                <br /><br />
                <strong>Scope of Work</strong>
                <br /><br />
                The Dip Auxiliary Package Project
                <br />
                Our team is executing the design, erection, and commissioning of
                a comprehensive electrical infrastructure project, featuring:-
                1cx630 (link unavailable) High-Tension (H.T) power cable laying,
                spanning approximately 27,900 meters- Installation of 600 mm and
                200 mm cable trays, totaling 8,100 metersThis project showcases
                our expertise in delivering complex electrical solutions,
                ensuring timely completion and exceptional quality."
              </p>
            </div>
          </div>
        </div>
        <hr /><br />
        
        <div className="directContracts">
          <h2 className="subheading slide-right">Direct Contracts</h2>
          {data1.map((project, index) => (
            <React.Fragment key={index}>
              <div className="projectColumn">
                <div className="picPan opacityF2T slide-left">
                  <img
                    src={project.img}
                    alt={project.heading}
                    className="project-image"
                  />
                </div>
                <div className="textPan slide-left">
                  <h2 className="heading">{project.heading}</h2>
                  <h3>{project.place}</h3>
                  <p className="project-description">{project.description}</p>
                </div>
              </div>
              {index !== data1.length - 1 && <hr />}
            </React.Fragment>
          ))}
        </div>
        <div className="subContracts">
          <h2 className="subheading slide-right">Sub-Contracts</h2>
          {data2.map((project, index) => (
            <React.Fragment key={index}>
              <div className="projectColumn">
                <div className="picPan opacityF2T slide-left">
                  <img
                    src={project.img}
                    alt={project.heading}
                    className="project-image"
                  />
                </div>
                <div className="textPan slide-left">
                  <h2 className="heading">{project.heading}</h2>
                  <h3>{project.place}</h3>
                  <p className="project-description">{project.description}</p>
                </div>
              </div>
              {index !== data2.length - 1 && <hr />}
            </React.Fragment>
          ))}
        </div>
      </section>
      <ProjectsContact />
    </div>
  );
};

export default Projects;
