import React from "react";
import Sec2 from "./Sec2/Sec2";
import Banner from "./AboutBanner/AboutBanner";
import WhyUs from './WhyUs/WhyUs';
import { Helmet } from "react-helmet";

function About() {
  return (
    <div className="about">
      <Helmet>
          <title>About E&I Engineers | Expertise in Electrical Engineering</title>
          <meta
          name="description"
          content="Discover E&I Engineers' commitment to excellence in electrical engineering. Learn about our comprehensive EPC solutions, specialized team, and dedication to empowering energy through engineering and instrumentation."
          />
      </Helmet>
      <Banner />
      <Sec2 />
      <WhyUs />
      {/* <Casestudy /> */}
    </div>
  )
}
export default About;
